/* !
 * top.js v1.0.0
 * Copyright, Connecty Inc.
 */
((FUNCTIONS) => {

	// MV
	// const splide_mv = new Splide('.top-mainVis.splide', {
	// 	type: 'loop',
	// 	perPage: 1,
	// 	autoplay: true,
	// 	interval: 5000,
	// 	speed: 1500,
	// 	arrows: true,
	// 	pagination: true,
	// 	paginationKeyboard: true,
	//  flickPower: 200,
	// 	pauseOnHover: false,
	// 	pauseOnFocus: false,
	// 	breakpoints: {
	// 		768: {
	// 			speed: 1000,
	// 		},
	// 	},
	// });

	// const video = document.querySelector('.top-mainVis.splide video');

	// video.load();

	// // 動画スライドの処理
	// const handleVideoSlide = () => {
	// 	if (video) {
	// 		video.play();
	// 		video.nextElementSibling.classList.remove('is-paused');

	// 		// 自動再生を停止して、動画が終わるまで待つ
	// 		splide_mv.Components.Autoplay.pause();

	// 		// 動画が終了したら次のスライドへ移動し、自動再生を再開
	// 		video.addEventListener('ended', () => {
	// 			splide_mv.Components.Autoplay.play();
	// 			splide_mv.go('>');
	// 		});
	// 	}
	// };

	// // Splide の準備が完了したら動画スライドを処理
	// splide_mv.on('mounted', () => {
	// 	handleVideoSlide();
	// });

	// // 次のスライドに移動したら動画スライドの再生を停止
	// splide_mv.on('move', (newIndex) => {
	// 	if (newIndex === 0) {
	// 		handleVideoSlide();
	// 	} else if (video) {
	// 		video.pause();
	// 		video.currentTime = 0;
	// 		video.nextElementSibling.classList.add('is-paused');
	// 	}
	// });

	// // Splide をマウント
	// splide_mv.mount();

	// 動画のplay/pauseボタン
	if (document.querySelectorAll('video.js-mainVideo').length) {
		const video = document.querySelectorAll('video.js-mainVideo');
		video.forEach(ele => {
			let button = ele.nextElementSibling;
			button.addEventListener('click', () => {
				if (ele.paused) {
					ele.play();
					button.classList.remove('is-paused');
				} else {
					ele.pause();
					button.classList.add('is-paused');
				}
			});
		});
	};

	// PICK UP
	const splide_pickup = new Splide('.top-pickup-slider.splide', {
		type: 'loop',
		autoplay: false,
		speed: 500,
		arrows: true,
		pagination: true,
		paginationKeyboard: true,
		flickPower: 200,
		autoWidth: true,
		gap: '32px',
		focus: 'center',
		breakpoints: {
			768: {
				gap: '16px',
			},
		}
	});
	splide_pickup.mount();

	// PICK UP 動画モーダル
	if (document.querySelectorAll('.js-modal-video').length) {
		new ModalVideo('.js-modal-video', {
			channel: 'youtube',
			autoplay: 1,
		});
	}

	// 新商品
	const prodNewSlider = document.querySelector('.top-prodNew-slider.splide');
	if (prodNewSlider.querySelectorAll('.splide__list li').length) {
		prodNewSlider.querySelectorAll('.splide__list li').forEach(ele => {
			if (!ele.getElementsByTagName('a').length) { ele.remove(); }
		});
		if (prodNewSlider.querySelectorAll('.splide__list li').length) {
			const splide_prodNew = new Splide('.top-prodNew-slider.splide', {
				type: 'slide',
				perPage: 5,
				perMove: 5,
				autoplay: false,
				speed: 500,
				arrows: true,
				pagination: true,
				paginationKeyboard: true,
				flickPower: 200,
				waitForTransition: false,
				gap: '20px',
				breakpoints: {
					768: {
						perPage: 3,
						perMove: 3,
					},
					640: {
						perPage: 2,
						perMove: 2,
						gap: '15px'
					},
				}
			});
			splide_prodNew.mount();

			// スライダーの矢印位置調整
			const updateArrowPosition = (_slide, _arrows) => {
				if (_slide) {
					const imageHeight = _slide.offsetHeight;
					_arrows.forEach(arrow => {
						arrow.style.top = `${imageHeight / 2}px`;
					});
				}
			};
			updateArrowPosition(prodNewSlider.querySelectorAll('.splide__slide img')[0], prodNewSlider.querySelectorAll('.splide__arrow'));
			window.addEventListener('resize', updateArrowPosition(prodNewSlider.querySelectorAll('.splide__slide img')[0], prodNewSlider.querySelectorAll('.splide__arrow')));
		} else {
			document.querySelector('.top-prodNew').remove();
		}
	} else {
		document.querySelector('.top-prodNew').remove();
	}

	// 商品カテゴリー もっと見るボタン
	const setMore = () => {
		let prodCat = document.querySelector('.top-prodCat');
		let prodCatList = document.querySelector('.top-prodCat-list');
		const timing = {
			duration: 300,
			easing: 'ease',
			iterations: 1,
			fill: 'forwards'
		};

		// 初期表示の高さをセット
		const minH = prodCatList.offsetHeight + 2;
		prodCatList.style.height = minH + 'px';

		const openCloseMore = (_e) => {
			if (_e.currentTarget.classList.contains('is-active')) {
				// Close
				_e.currentTarget.classList.remove('is-active');
				if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.desktop) {
					let pos = prodCat.getBoundingClientRect().top + window.scrollY - 20;
					window.scrollTo({ top: pos, behavior: 'smooth' });
					setTimeout(() => {
						prodCatList.classList.remove('is-open');
						prodCatList.animate({ height: minH + 'px' }, timing);
					}, 100);
				} else {
					prodCatList.classList.remove('is-open');
					prodCatList.animate({ height: minH + 'px' }, timing);
				}
			} else {
				// Open
				_e.currentTarget.classList.add('is-active')
				prodCatList.classList.add('is-open');
				prodCatList.animate({ height: (prodCatList.scrollHeight + 2) + 'px' }, timing);
			}
		}
		document.querySelector('.js-prodCat-more').addEventListener('click', event => {
			openCloseMore(event);
		});
		document.querySelector('.js-prodCat-more').addEventListener('keydown', event => {
			if (event.key === 'Escape') {
				openCloseMore(event);
			}
		});

	};
	setMore();

	// 商品サイト
	document.querySelectorAll('.top-prodBrand-slider .splide__slide').forEach(ele => {
		if (!ele.getElementsByTagName('a').length) {
			ele.remove()
		}
	});
	const splide_prodBrand = new Splide('.top-prodBrand-slider.splide', {
		type: 'loop',
		autoplay: true,
		interval: 3000,
		speed: 400,
		arrows: true,
		pagination: true,
		paginationKeyboard: true,
		flickPower: 200,
		pauseOnHover: false,
		autoWidth: true,
		gap: '20px',
		focus: 'center',
		updateOnMove: true,
		cloneStatus: false,
		breakpoints: {
			768: {
				gap: '16px',
			},
		}
	});
	splide_prodBrand.mount();


})(window.FUNCTIONS);
